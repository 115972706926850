<template>
  <div 
    style="min-height: calc(100vh);padding:5px;"
  >
  <table 
    v-if="weekDay"
    cellspacing="0" cellpadding="0"
    style="border: 5px solid white;width: 100%;margin-bottom: 20px;border-radius: 5px;border-spacing: 5px 0 5px 5px !important;background-color:white;" 
  >
    <tr style="height: 60px;">
      <td colspan="7" style="vertical-align: middle;text-align: center; padding: 0 10px;font-size:1.2rem;background-color: white !important;">
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="4"
            style="text-align: left;"
          >
              <copy-week
                v-if="weekDay && weekDay.length > 0 && weekDay[0].date"
                :actualDate="weekDay[0].date"
                :planID="planID"
                :contentTypeID="contentTypeID"
              />
              <!--v-alert
                v-if="config.romanticClientID"
                dense
                color="red"
                dark
                icon="mdi-lock"
                border="right"
                style="max-width: 150px;"
              >
                Romantic
              </v-alert-->
            <!--totem-preview
              :workspaceID="workspaceID"
              :locale="locale"
              color="primary"
            /-->
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-btn
              @click="() => { selectedWeek-- }"
                style="margin-right: 20px;"
                color="primary"
                dark
            >
              <v-icon>mdi-arrow-left-thick</v-icon>
            </v-btn>
              <b> {{ config.id.replace('-', ' ').toUpperCase() }}</b> <small>[{{getRange(weekDay)}}]</small>
            <v-btn
              @click="() => { selectedWeek++ }"
                style="margin-left: 20px;"
                color="primary"
                dark
            >
              <v-icon>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            style="text-align: right;"
          >
        <export-to-excel
          :contentTypeID="contentTypeID"
          :config="config"
          :locale="locale"
        />
            <!--v-btn
              style="margin: 0 5px 5px 0;"
              @click="hideRec = !hideRec"
              :color="hideRec ? 'red' : 'green'"
              small
              dark
              elevation="0"
            >
                <v-icon small style="margin-right:2px;">{{ hideRec ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                <v-icon small>mdi-book-sync</v-icon>
            </v-btn-->
            <!--v-btn-toggle
              v-model="eventType"
              borderless
              color="primary"
              mandatory
                dense
            >
              <v-btn value="cardOff">
                <v-icon>
                  mdi-card-bulleted-off
                </v-icon>
              </v-btn>
              <v-btn value="cardOn">
                <v-icon>
                  mdi-card-bulleted
                </v-icon>
              </v-btn>
            </v-btn-toggle-->
          </v-col>
        </v-row>
        <form-event
          v-if="allActivities && editedItem"
          :value="editedItem"
          :allActivities="allActivities"
          :planID="planID"
          :contentTypeID="contentTypeID"
          :onClose="handleCloseForm"
          :config="config"
          :locale="locale"
          :defaultSubcategoryID="editedItem.SubcategoryID"
        />
        <!--hide-recurrent
          :contentTypeDataID="contentTypeDataID"
          :hideEvent="hideRecurrentEvent"
          :locale="locale"
          :onClose="handleCloseHideReccurent"
        /-->
      </td>
    </tr>
  <!-- ini meal-->
    <tr style="height: 50px;background-color:#cccccc80;">
      <th
        v-for="(day, index) of weekDay"
        :key="`day${day.date}`"
        style="width: 12.5%;padding: 5px 5px;color:black;border-left: 2px solid white;border-right: 2px solid white;" 
      >
        <v-btn 
          :color="config.color"
          :dark="day.old || !checkWeekday(day.date, weekdayConfig) ? false : config.dark"
          block
          @click="handleAdd(day)"
          :disabled="day.old || !checkWeekday(day.date, weekdayConfig)"
        >
            <span :style="{ textDecoration: day.old ? 'line-through #CCCCCC 3px' : null }">{{ $t(`common.s${index}`, locale)}} {{day.date.split('-').pop()}}</span>
              <v-icon right dense v-if="!day.old && checkWeekday(day.date, weekdayConfig)">mdi-plus</v-icon>
        </v-btn>
      </th>
    </tr>
    <tr>
      <td
        v-for="(day) of weekDay"
        :key="`list${day.date}`"
        style="width: 12.5%;padding: 5px 5px;color:black;vertical-align: top;background-color:#cccccc80;border-left: 2px solid white;border-right: 2px solid white;" 
      >
        <weekday-selector
          v-if="weekdayConfig"
          :config="config.weekday"
          :planID="planID"
          :contentTypeID="contentTypeID"
          :weekdayConfig="weekdayConfig"
          :day="day"
          :locale="locale"
          :onClose="(date, v) => { if (date) handleChangeWeekdayID(date, v) }"
        />
        <list-day
          v-if="allActivities"
          :day="day"
          :config="config.weekday"
          :planID="planID"
          :contentTypeID="contentTypeID"
          :onEdit="handleEdit"
          :locale="locale"
          :forceRefresh="forceRefresh"
          :activityList="allActivities"
          :eventType="eventType"
          :old="day.old"
          :disabledAdd="day.old || !checkWeekday(day.date, weekdayConfig)"
        />
      </td>
    </tr>
  </table>
  </div>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import ExportToExcel from './ExportToExcel'
//import TotemPreview from './TotemPreview'
import ListDay from './ListDay'
import FormEvent from './Form'
import WeekdaySelector from './WeekdaySelector'
import CopyWeek from './CopyWeek'
//import HideRecurrent from './HideRecurrent'
export default {
  components: {
    ExportToExcel,
    FormEvent,
    WeekdaySelector,
    //TotemPreview,
    ListDay,
    //HideRecurrent,
    CopyWeek,
  },
  props: {
    contentTypeID: {
      type: String,
      required: true,
    },
    planID: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      requried: true,
    },
    locale: {
      type: String,
      required: true,
    },
    /*
    workspaceID: {
      type: String,
      required: true,
    },
    timezone: {
      type: String,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    contentTypeDataID: {
      type: String,
      required: true,
    },
    contentTypeData: {
      type: Object,
      required: true,
    },
    onEdit: {
      type: Function,
      requried: true,
    },*/
  },
  data: () => ({
    loading: false,
    weekDay: null,
    eventType: 'cardOff', // view mode card / tag
    allActivities: null,

    selectedWeek: 0,

    editedItem: null,
    forceRefresh: null,

    weekdayConfig: {},
    /*

    activityList: null,
    categoryFilter: null,
    hideRec: null,
    hideRecurrentEvent: false,
    */
  }),
  computed: {
    /*
    fromActivityList () {
      return !this.fromManager && this.contentTypeData.SelectableActivities ? true : false
    },
    categories () {
      if (!this.contentTypeData) return
      const aux = JSON.parse(JSON.stringify(this.contentTypeData))
      return aux ? aux.Categories.map(item => {
        item.Name = utils.getTranslation(item.Name, this.locale)
        return item
      }) : null
    },
    */
  },
  watch: {
    contentTypeID () {
      this.handleGetSelectableActivities()
    },
    planID () {
      this.handleGetSelectableActivities()
    },
    selectedWeek () {
      this.handlePrepareWeekDay()
    },
    /*
    'contentTypeData.SelectableActivities' () {
      this.handleGetSelectableActivities()
    },
    contentTypeDataID () {
      this.handleGetSelectableActivities()
      this.handlePrepareWeekDay()
    },
    eventType (v) {
      localStorage.setItem("eventTypeDviewcontent", v)
    },
    */
  },
  mounted () {
    this.prepareEventType()
    this.handleGetSelectableActivities()
    this.handlePrepareWeekDay()
  },
  methods: {
    checkWeekday (day, weekdayConfig) {
      if (!weekdayConfig || weekdayConfig[day]) return true
      return false
    },
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    prepareEventType () {
      this.eventType = 'cardOff'
      /*
      if (localStorage.getItem('eventTypeDviewcontent') && localStorage.getItem('eventTypeDviewcontent').length > 0 && localStorage.getItem('eventTypeDviewcontent') !== this.eventType) 
        this.eventType = localStorage.getItem('eventTypeDviewcontent') 
        */
    },
    handleGetSelectableActivities () {
      this.loading = true
      this.allActivities = null
      const url = `v1/private/activity-barcelo-01/manager-events/${this.contentTypeID}/${this.planID}`
      api.getAll ('dviewcontent', url, 0, 10000, '')
        .then(response => {
          this.allActivities = response ? response.map(item => {
            item.Name = this.getTranslation(item.Title, this.locale)
            item.Title = JSON.parse(item.Title)
            item.Description = JSON.parse(item.Description)
            item.Place = JSON.parse(item.Place)
            return item
          }) : null
          this.loading = false
        })
        .catch (error => {
          console.log(error)
        })
    },
    getRange (v) {
      let ini = v[0].date.split('-')
      ini.shift()
      let end = v[6].date.split('-')
      end.shift()
      return `del ${ini.join('/')} al ${end.join('/')}`
    },
    addDays (v, daysToAdd) {
      return new Date(new Date(v).setDate(new Date(v).getDate() + daysToAdd))
    },
    handleChangeWeekdayID (date, v) {
      this.weekDay= this.weekDay.map(item => {
        if (item.date === date) item.weekdayID = v
        return item
      })
      this.weekdayConfig[date] = v
      this.forceRefresh = new Date().toISOString()
    },
    async handlePrepareWeekDay () {
      try {
        let result = []
        const today = this.selectedWeek ? this.addDays(new Date(), this.selectedWeek * 7) : new Date()
        let actualDay = ((today.getDay() === 0 ? 7 : today.getDay()))*-1
        for (let i = 0; i < 7; i++) {
          actualDay++
          result.push({
            contentTypeID: this.contentTypeID,
            planID: this.planID,
            i,
            date: this.addDays(today, actualDay).toISOString().split('T').shift(),
            old: this.selectedWeek < 0 || (this.selectedWeek === 0 && actualDay < 0),
            weekdayID: null
          })
        }

        // ini
        //this.weekdayConfig = {}
        if (this.config.weekday) {
          const dates = result.map(item => item.date).join(',')
          const url = `v1/private/activity-barcelo-01/weekday/${this.contentTypeID}/${this.planID}/${dates}`
          this.weekdayConfig = await new Promise((resolve, reject) => api.getAll ('dviewcontent', url, 0, 10000, '')
            .then(response => {
              resolve(response)
              //this.weekdayConfig = response
            })
            .catch (error => {
              reject(error)
            }))
          result = result.map(item => {
            item.weekdayID = this.weekdayConfig[item.date]
            return item
          })
        } else {
          this.weekdayConfig = null
        }
        // end
        //this.handleGetWeekdayConfig()
        this.weekDay = result

      } catch (error) {
        console.log(error)
      }
    },
    handleAdd (v) {
      if (this.config.blocked) {
        alert('Gesti�n bloqueada')
        return
      }

      this.editedItem = {
        EventID: null,
        ContentTypeID: this.contentTypeID,
        PlanID: this.planID,
        Time: 960,
        TimeEnd: 1020,
        Date: v.date,
        WeekdayID: v.weekdayID,
      }
    },
    handleCloseForm (refresh) {
      if (refresh) this.forceRefresh = new Date().toISOString()
      this.editedItem = null
    },
    handleEdit (v) {
      if (v.Readonly) {
        alert('Romantic')
        return
      }
      if (this.config.blocked) {
        alert('Gesti�n bloqueada')
        return
      }
      this.editedItem = v
    }
    /*
    handleEdit (v) {
      if (v.Readonly) {
        alert (`No editable ${v.Source === 'romantic' ? '(ROMANTIC)' : ''}`)
        return
      }
      if (v.Recurrent) {
        this.hideRecurrentEvent = v
        //alert ('Las actividades recurrentes no se pueden editar desde este panel.')
        return
      }
      this.editedItem = v
    },


    // recurrent
    handleCloseHideReccurent (refresh) {
      if (refresh) this.forceRefresh = new Date().toISOString()
      this.hideRecurrentEvent = null
    },*/
  },
}
</script>

